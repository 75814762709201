export const BASE_URL = "https://192.1.7.65/"; //서버 1
// export const BASE_URL = "http://147.47.18.31:8082/"; // 서버 2(개발용) - 신버전(사각형 테두리를 위한 좌표 두개 전달)

const connector = {
  connectFetchController: async (
    path,
    method,
    body,
    callBack,
    errorCallBack
  ) => {
    return fetch(`${BASE_URL}${path}`, {
      // credentials: 'include',
      method: method,
      body: body ? body : null,
      headers: {
        "content-type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (callBack) callBack(data);
        return data;
      })
      .catch(function (e) {
        console.log(e);
        try {
          let data = e.json();
          if (errorCallBack) errorCallBack(e.json());
        } catch (err) {
          if (errorCallBack) errorCallBack(e);
        }
      });
  },

  jsonConnector: async (path, method, body, token, callBack, errorCallBack) => {
    return fetch(`${BASE_URL}${path}`, {
      credentials: "include",
      method: method,
      body: body ? JSON.stringify(body) : null,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${getCookie("jwt")}`,
        // Authorization: "Bearer " + (token? token : null),
      },
    })
      .then(async function (res) {
        if (res.status < 200 || res.status >= 255) {
          throw await res.json();
        }
        return res.json();
      })
      .then(function (data) {
        if (callBack) callBack(data);
        return data;
      })
      .catch(function (e) {
        if (errorCallBack) errorCallBack(e);
      });
  },
  uploadConnector: async (path, method, formData, token, callBack, errorCallBack) => {

    return await fetch(`${BASE_URL}${path}`, {
      method: method,
      body: formData,
      headers: {
        Authorization: `Bearer ${getCookie("jwt")}`,
        Refresh: `${getCookie("refresh")}`,
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (callBack) callBack(data);
        return data;
      })
      .catch(function (e) {
        if (errorCallBack) errorCallBack(e);
      });
  },
  authConnector: async (path, method, body, token, callBack, errorCallBack) => {
    // console.log(`Bearer ${getCookie("jwt")}`);
    // console.log("BASE_URL => ", BASE_URL);
    // console.log("path => ", path);
    console.log("full url =>", `${BASE_URL}${path}`);
    return fetch(`${BASE_URL}${path}`, {
      // credentials: 'include',
      method: method,
      body: body ? JSON.stringify(body) : null,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("jwt")}`,
        Refresh: `${getCookie("refresh")}`,
        // Authorization: "Bearer " + (token? token : null),
      },
    })
      .then(async function (res) {
        if (res.status < 200 || res.status >= 255) {
          console.log("여기는 들어왔니?");
          throw await res.json();
        }
        return res.json();
      })
      .then(function (data) {
        if (callBack) callBack(data);
        return data;
      })
      .catch(async function (e) {
        // console.log("auth error : ", e);
        try {
          let res;
          if (e?.error == "로그인 후 사용하세요.") {
            res = await connector.refreshConnector();
            if (res?.accessToken) {
              document.cookie = `jwt=${res.data}`;
              document.cookie = `refresh=${res.data}`;
              connector.jsonConnector(path, method, body);
            } else {
              alert(res?.error);
            }
            return;
          } else if (e?.message == "유저 정보가 일치하지 않거나, 다른 유저가 새롭게 접속했습니다." || e?.message == "이 페이지는 해당 권한으로 접속할 수 없습니다.") {
            // alert(e?.message);
            // return;
            window.location.href = `/login?error=${e?.message}`;
            // window.location.reload();
          }
          // console.log(e);
          throw e;
        } catch (ee) {
          return e;
        }
      });
  },

  refreshConnector: async (callBack, errorCallBack) => {
    return fetch(`${BASE_URL}users/refresh`, {
      // credentials: 'include',
      method: "GET",
      body: null,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("jwt")}`,
        Refresh: `${getCookie("refresh")}`,
      },
    })
      .then(async function (res) {
        if (res.status != 200) {
          throw await res.json();
        }
        return res.json();
      })
      .then(function (data) {
        if (callBack) callBack(data);
        return data;
      })
      .catch(function (e) {
        console.log(e);
        if (errorCallBack) errorCallBack(e);
        return e;
      });
  },
};

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default connector;
